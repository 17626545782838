<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        :value="invoiceItemTemplate.name"
        outlined
        label="Nazwa usługi"
        :rules="[rules.required]"
        placeholder="Wpisz nazwę usługi"
        @input="emitUpdate('invoiceItemTemplate.name',$event)"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        outlined
        label="Wartość netto usługi [PLN]"
        :value="invoiceItemTemplate.netValue"
        type="number"
        :rules="[rules.required,rules.money, rules.lowerThan(10000001)]"
        placeholder="Wpisz wartość netto usługi"
        @input="emitUpdate('invoiceItemTemplate.netValue',$event)"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        :value="invoiceItemTemplate.vatPercentage"
        outlined
        label="Stawka vat [%]"
        type="number"
        :rules="[rules.required, rules.lowerThan(101)]"
        placeholder="Wpisz stawkę vat"
        @input="emitUpdate('invoiceItemTemplate.vatPercentage',$event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import rules from '../../../utils/validators'

export default {
  props: {
    invoiceItemTemplate: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    rules
  }),
  methods: {
    emitUpdate (key, value) {
      this.$emit('update', { key, value })
    },
  }
}
</script>
